import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { LocalizedLink } from "gatsby-theme-i18n"
import { GatsbyImage } from "gatsby-plugin-image"

const Index = ({ data }) => {
  const [showMore, setShowMore] = React.useState(false)

  const { description, title } = data.pageData.frontmatter
  const videos = data.videos.nodes

  return (
    <Layout>
      <Seo title={title} description={description} />
      <div className="shell">
        <h1>
          {title}
          <span class="dotRed">.</span>
        </h1>

        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="description"
        />

        <div className="videos__grid">
          {videos.map((node, index) => {
            const { slug, title, featuredImage } = node.childMdx.frontmatter
            return (
              <LocalizedLink
                to={slug}
                key={`video_${index}`}
                style={{ textDecoration: "none" }}
              >
                <div className="videos__grid_post">
                  <GatsbyImage
                    image={featuredImage.childImageSharp.gatsbyImageData}
                    alt={title}
                    className="videos__grid_post-image"
                  />
                  <div className="videos__grid_post-text">
                    <div>
                      <h4 className="videos__grid_post-title">{title}</h4>
                    </div>
                  </div>
                </div>
              </LocalizedLink>
            )
          })}
        </div>
        {!showMore && videos.length > 4 && (
          <div className="videos__view-more">
            <button
              className="videos__view-more_button"
              onClick={() => setShowMore(true)}
            >
              Voir plus
            </button>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query ($locale: String!) {
    pageData: mdx(
      fileAbsolutePath: { regex: "/static-pages/videos/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
      }
    }
    videos: allFile(
      filter: {
        sourceInstanceName: { eq: "videos" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
